import { Service, Settings, STEP_NAMES, StepNames } from "../../shared";
import { List } from "immutable";

interface Props {
  firstPage?: StepNames;
  currentStepName: StepNames | null;
  selectedProductHasAddons?: boolean;
  settings?: Settings;
  isVirtualProductsBeforeStore?: boolean;
  products: List<Service>;
  showSummaryMobile: boolean;
  selectedAddOns: Service[] | void;
  isVenuesDeeplink?: boolean;
  isProductsBeforeStore?: boolean;
  from?: string | string[];
}
export const getShowBackArrow = ({
  firstPage,
  currentStepName,
  selectedProductHasAddons,
  settings,
  isVirtualProductsBeforeStore,
  products,
  showSummaryMobile,
  selectedAddOns,
  isVenuesDeeplink,
  isProductsBeforeStore,
  from,
}: Props) => {
  const showStaffSelectionAfterCalendar =
    settings?.staffOptions.showStaffSelectionAfterCalendar;

  const userSkippedOrReselectingAddon =
    selectedAddOns && selectedAddOns.length === 0;

  if (
    currentStepName === STEP_NAMES.PRODUCTS &&
    firstPage === STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE
  ) {
    return false;
  }

  if (
    currentStepName === STEP_NAMES.ADDONS &&
    !settings?.showProductSelectionIfSingleProduct &&
    products.size <= 1 &&
    firstPage === STEP_NAMES.PRODUCTS
  ) {
    return false;
  }

  if (
    currentStepName === STEP_NAMES.PRODUCTS &&
    firstPage === STEP_NAMES.PRODUCTS_BEFORE_STORE
  ) {
    return false;
  }

  if (
    (currentStepName === STEP_NAMES.PRODUCTS ||
      (currentStepName === STEP_NAMES.SLOTS && userSkippedOrReselectingAddon) ||
      currentStepName === STEP_NAMES.ADDONS) &&
    !(
      currentStepName === STEP_NAMES.SLOTS &&
      settings &&
      settings.staffOptions.showStaffSelection &&
      !settings.staffOptions.showStaffSelectionAfterCalendar
    ) &&
    firstPage === STEP_NAMES.VENUES &&
    isVenuesDeeplink
  ) {
    return false;
  }

  if (
    currentStepName === STEP_NAMES.DETAILS &&
    firstPage === STEP_NAMES.LIVE_CHAT_PRODUCTS &&
    !settings?.showProductSelectionIfSingleProduct &&
    products.size <= 1
  ) {
    return false;
  }

  if (
    currentStepName === STEP_NAMES.VENUES &&
    isProductsBeforeStore &&
    (settings?.showProductSelectionIfSingleProduct ||
      products.size > 1 ||
      selectedProductHasAddons)
  ) {
    return true;
  }

  if (
    currentStepName === STEP_NAMES.VENUES &&
    (firstPage === STEP_NAMES.TYPE || from === "/type")
  ) {
    return true;
  }

  if (
    currentStepName === STEP_NAMES.SLOTS &&
    isVirtualProductsBeforeStore &&
    firstPage === STEP_NAMES.TYPE
  ) {
    return true;
  }

  if (
    settings &&
    settings.showQueueSelection &&
    currentStepName === STEP_NAMES.PRODUCTS &&
    firstPage === STEP_NAMES.PRODUCTS
  ) {
    return true;
  }

  if (
    currentStepName === STEP_NAMES.ADVISORS &&
    isVenuesDeeplink &&
    from?.includes(STEP_NAMES.ADVISORS)
  ) {
    return false;
  }

  if (
    currentStepName === STEP_NAMES.ADVISORS &&
    isVenuesDeeplink &&
    settings &&
    !selectedProductHasAddons &&
    products.size <= 1 &&
    !settings?.showProductSelectionIfSingleProduct &&
    !showStaffSelectionAfterCalendar
  ) {
    return false;
  }

  const showArrow = !(
    currentStepName === firstPage ||
    currentStepName === STEP_NAMES.VENUES ||
    currentStepName === STEP_NAMES.BOOKING ||
    currentStepName === STEP_NAMES.CANCELLATION ||
    currentStepName === STEP_NAMES.ERROR ||
    (currentStepName === STEP_NAMES.SLOTS &&
      !selectedProductHasAddons &&
      !settings?.showProductSelectionIfSingleProduct &&
      isVirtualProductsBeforeStore &&
      products.size <= 1) ||
    (currentStepName === STEP_NAMES.ADDONS && firstPage === STEP_NAMES.SLOTS) ||
    (currentStepName === STEP_NAMES.ADVISORS &&
      firstPage === STEP_NAMES.SLOTS &&
      !showStaffSelectionAfterCalendar) ||
    (currentStepName === STEP_NAMES.QUEUES &&
      firstPage === STEP_NAMES.PRODUCTS) ||
    !currentStepName ||
    showSummaryMobile
  );

  return showArrow;
};
