import React from "react";
import { useTranslation } from "react-i18next";
import {
  allowedOverflow,
  AppParagraph1,
  AppParagraph2,
  AppParagraph3,
  ProductIcon,
  Service,
  Settings,
  shouldShowServiceDuration,
  shouldShowServicePricing,
  Theme,
} from "../../../shared";
import { localizeCurrency } from "../../../utils/currency";
import { humanizeTime } from "../../../utils/time";
import { SummaryCard } from "./summaryCard";

export interface ProductCardProps {
  service: Service;
  linkText?: string;
  onLinkClick?: () => void;
  selectedAddOns: Service[] | void;
  theme?: Theme;
  settings: Settings;
  loading?: boolean;
}

type Props = ProductCardProps;

export const ProductSummary: React.FunctionComponent<Props> = ({
  service,
  linkText,
  onLinkClick,
  selectedAddOns,
  theme,
  settings,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const showServiceDuration = shouldShowServiceDuration(settings);
  const showServicePricing = shouldShowServicePricing(settings);

  const getTotalPrice = () => {
    if (showServicePricing) {
      const servicePrice = Number(service.price) || 0;
      const addOnsPrice = selectedAddOns
        ? selectedAddOns.reduce((prev, current) => {
            return prev + (Number(current.price) || 0);
          }, 0)
        : 0;

      return servicePrice + addOnsPrice;
    }
    return 0;
  };

  const renderProduct = () => {
    const totalPrice = getTotalPrice();

    const renderPrice = (price?: number, currency?: string | void) => {
      return price && currency ? localizeCurrency(price, currency) : "";
    };

    const renderDuration = (duration = 0) => humanizeTime(duration);

    const renderTotalDuration = () => {
      const serviceDuration = service.serveTimeInMinutes || 0;
      const addOnsDuration = selectedAddOns
        ? selectedAddOns.reduce((prev, current) => {
            return prev + (current.serveTimeInMinutes || 0) || 0;
          }, 0)
        : 0;

      const totalDuration = serviceDuration + addOnsDuration;

      return renderDuration(totalDuration);
    };

    const renderRow = (
      id: string,
      name: string | any,
      duration?: number,
      price?: number,
      currency?: string
    ) => {
      const localizedPrice = showServicePricing && renderPrice(price, currency);
      const humanizedDuration = showServiceDuration && renderDuration(duration);

      return (
        <div key={id}>
          <div className={"w-100 mb-3"}>
            <div
              className={"w-100 d-flex flex-row justify-content-between mb-1"}
            >
              <AppParagraph2
                id={`summary-card-service-name-${id}`}
                className={"mr-2 text-left"}
                style={{
                  ...allowedOverflow,
                  color: theme && theme.body.font.mainTextColor,
                }}
              >
                {name}
              </AppParagraph2>
              {showServicePricing && (
                <AppParagraph2
                  id={`summary-card-service-price-${id}`}
                  style={allowedOverflow}
                >
                  {localizedPrice}
                </AppParagraph2>
              )}
            </div>
            {showServiceDuration && duration !== 0 && (
              <div className={"w-100 d-flex flex-row"}>
                <AppParagraph3
                  id={`summary-card-service-duration-${id}`}
                  style={{
                    ...allowedOverflow,
                    color: theme && theme.body.font.secondaryTextColor,
                  }}
                >
                  {humanizedDuration}
                </AppParagraph3>
              </div>
            )}
          </div>
        </div>
      );
    };

    const currencyCode =
      service.currencyCode ||
      (selectedAddOns && selectedAddOns[0] && selectedAddOns[0].currencyCode);

    return (
      <div className="d-flex w-100 flex-column">
        {renderRow(
          service.id,
          service.name,
          service.serveTimeInMinutes,
          Number(service.price),
          service.currencyCode
        )}
        {selectedAddOns &&
          selectedAddOns.map((s) =>
            renderRow(
              s.id,
              `+ ${s.name}`,
              s.serveTimeInMinutes,
              Number(s.price),
              s.currencyCode
            )
          )}
        {(showServiceDuration || (showServicePricing && totalPrice > 0)) && (
          <hr className={"w-100 mt-0 mb-2"} />
        )}
        {showServicePricing && totalPrice > 0 && (
          <div className="w-100 d-flex flex-row justify-content-end mb-3">
            <AppParagraph3
              id={"summary-card-service-name-total"}
              className={"text-uppercase mt-1 mr-3"}
              style={allowedOverflow}
            >
              {t("total", { defaultValue: "Total" })}
            </AppParagraph3>
            <AppParagraph1
              id={"summary-card-service-price-total"}
              style={{
                ...allowedOverflow,
                color: theme && theme.body.font.mainTextColor,
                lineHeight: "22px",
              }}
            >
              {renderPrice(totalPrice, currencyCode)}
            </AppParagraph1>
          </div>
        )}
        {showServiceDuration && (
          <div
            className="w-100 d-flex flex-column align-items-end"
            style={{ justifyContent: "space-between" }}
          >
            <AppParagraph3 style={allowedOverflow}>
              {t("serviceDuration", { defaultValue: "Service duration" })}
            </AppParagraph3>
            <AppParagraph3
              id={"summary-card-service-time-total"}
              style={{
                ...allowedOverflow,
                color: theme && theme.body.font.mainTextColor,
              }}
            >
              {renderTotalDuration()}
            </AppParagraph3>
          </div>
        )}
      </div>
    );
  };

  return (
    <SummaryCard
      id={`summary-card-service`}
      icon={
        <ProductIcon
          size={14}
          title={`${t("service", { defaultValue: "Service" })} icon`}
        />
      }
      linkText={linkText}
      onLinkClick={onLinkClick}
      title={t("service", { defaultValue: "Service" })}
    >
      {loading ? (
        <div className="animated-background" style={{ height: 133 }} />
      ) : (
        renderProduct()
      )}
    </SummaryCard>
  );
};
