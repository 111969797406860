import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  DeepPartial,
  Store,
} from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { detailsReducer } from "../components/details/reducer";
import {
  changeLanguagesEpic,
  setLanguagesEpic,
  ssoRedirectEpic,
} from "../components/layout/epics";
import { layoutReducer } from "../components/layout/reducer";
import { LayoutState } from "../components/layout/types";
import { productsReducer } from "../components/products/reducer";
import {
  addAttachmentEpic,
  deleteAttachmentEpic,
  preSignedUrlRequestCompletedEpic,
} from "../components/questions/epics";
import { uploadReducer } from "../components/questions/reducer";
import { UploadState } from "../components/questions/types";
import { queueReducer, QueueState } from "../components/queues/reducer";
import { dismissToastEpic } from "../components/slotsSelection/epics";
import { slotsReducer } from "../components/slotsSelection/reducer";
import { bookingTypeReducer } from "../components/bookingTypes/reducer";
import { coordinatesReducer } from "../components/venueSelector/reducers";
import { makeRequestEpic } from "../services/api/epics";
import { apiReducer } from "../services/api/reducer";
import { videoMeetingReducer } from "../components/bookingVideoMeeting/reducer";

export interface ApplicationState {
  customerDetails: any;
  layout: LayoutState;
  api: any;
  slots: any;
  geolocation: any;
  products: any;
  videoMeeting: any;
  queue: QueueState;
  upload: UploadState;
  bookingType: any;
}

export function initStore(state?: DeepPartial<ApplicationState>): Store {
  let rootEpic;

  process.browser
    ? (rootEpic = combineEpics(
        makeRequestEpic,
        setLanguagesEpic,
        changeLanguagesEpic,
        addAttachmentEpic,
        preSignedUrlRequestCompletedEpic,
        deleteAttachmentEpic,
        dismissToastEpic,
        ssoRedirectEpic
      ))
    : (rootEpic = combineEpics());

  const rootReducer = combineReducers<ApplicationState>({
    api: apiReducer,
    customerDetails: detailsReducer,
    geolocation: coordinatesReducer,
    layout: layoutReducer,
    products: productsReducer,
    videoMeeting: videoMeetingReducer,
    queue: queueReducer,
    slots: slotsReducer,
    upload: uploadReducer,
    bookingType: bookingTypeReducer,
  });

  const epicMiddleware = createEpicMiddleware();

  const composeEnhancers = process.browser
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

  const store: Store<ApplicationState> = createStore(
    rootReducer,
    state,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(rootEpic);

  return store;
}
