import { List } from "immutable";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import {
  Advisor,
  Booking,
  getDistanceUnit,
  Reservation,
  Service,
  Settings,
  StepNames,
  Venue,
} from "../../shared";
import { Questions } from "../../shared/interfaces/questions";
import { ApplicationState } from "../../store";
import { getFirstPageFromRouter } from "../../utils/getFirstPageFromRouter";
import {
  getBookingDate,
  getSingleAdvisorResponse,
} from "../advisorSelector/selectors";
import { setBookingType } from "../bookingTypes/actions";
import { getSelectedBookingType } from "../bookingTypes/selectors";
import { CardBookingValidTypes } from "../bookingTypes/types";
import {
  getBookingResponse,
  getReservationResponse,
} from "../details/selectors";
import {
  clearAddOn,
  clearAll,
  clearCoordinates,
  clearProduct,
  clearVenue,
  setFirstPage,
} from "../layout/actions";
import { UserCoordinatesContext } from "../layout/LayoutContainer";
import {
  getCurrentLanguage,
  getFirstPage,
  getSettingsResponse,
} from "../layout/selectors";
import { setCurrentLayout } from "../products/actions";
import {
  getHasOnlyVirtualProducts,
  getIsPriceInvolved,
  getProductsAggregate,
  getSelectedAddOns,
  getSelectedProductHasAddons,
  isLoadingProducts,
} from "../products/selectors";
import { getResponse, isLoadingQuestions } from "../questions/selectors";
import { getQueueId } from "../queues/selectors";
import {
  getCurrentLayout,
  getIsLiveChat,
  getSingleProductResponse,
  getSingleVenueResponse,
  isLoadingBooking,
  isLoadingProduct,
  isLoadingReservation,
  isLoadingVenue,
} from "./selectors";
import { SummaryLayout } from "./SummaryLayout";

interface SummaryContainerProps {
  isVirtualAppointment?: boolean;
}
interface DispatchProps {
  setLayout: (layoutType: string) => void;
  setBookingType: (bookingType: CardBookingValidTypes) => void;
  clearProduct: () => void;
  clearCoordinates: () => void;
  clearAddOn: () => void;
  clearVenue: () => void;
  clearAll: () => void;
  setFirstPage: (page: StepNames) => void;
}

interface StateProps {
  advisor: Advisor | void;
  booking?: Booking;
  bookingDate: string | void;
  currentLayoutType: string;
  firstPage: StepNames;
  isLoadingBooking: boolean;
  isLoadingReservation: boolean;
  isLoadingProduct: boolean;
  isLoadingProducts: boolean;
  isLoadingVenue: boolean;
  product: Service;
  questions: Questions[];
  reservation: Reservation | void;
  selectedAddOns: Service[] | void;
  settings: Settings | void;
  venue: Venue;
  products: List<Service>;
  onlyVirtualProducts: boolean;
  storedQueueId?: string;
  language: string;
  selectedBookingType?: CardBookingValidTypes;
  selectedProductHasAddons?: boolean;
  isLiveChat?: boolean;
  showPaymentStep: boolean;
}

type Props = SummaryContainerProps & StateProps & DispatchProps;

const SummaryContainer: React.FunctionComponent<Props> = (props) => {
  const hasSingleProduct = props.products && props.products.size === 1;
  const { coordinates, geolocationError } = useContext(UserCoordinatesContext);
  const [cachedBooking, setCachedBooking] = useState(props.booking);

  useEffect(() => {
    if (!cachedBooking) {
      setCachedBooking(props.booking);
    }
  }, [props.booking, cachedBooking]);

  useEffect(() => {
    if (!props.firstPage) {
      props.setFirstPage(getFirstPageFromRouter());
    }
  }, []);

  if (props.settings) {
    return (
      <SummaryLayout
        advisor={props.advisor}
        bookingDate={props.bookingDate}
        booking={cachedBooking as Booking}
        currentLayoutType={props.currentLayoutType}
        firstPage={props.firstPage}
        isLoadingVenue={props.isLoadingVenue}
        isLoadingBooking={props.isLoadingBooking}
        isLoadingReservation={props.isLoadingReservation}
        isLoadingProduct={props.isLoadingProduct}
        isLoadingProducts={props.isLoadingProducts}
        product={props.product}
        questions={props.questions}
        reservation={props.reservation}
        selectedAddOns={props.selectedAddOns}
        settings={props.settings}
        venue={props.venue}
        hasSingleProduct={hasSingleProduct}
        setProductsLayout={props.setLayout}
        setBookingType={props.setBookingType}
        isVirtualAppointment={props.isVirtualAppointment}
        onlyVirtualProducts={props.onlyVirtualProducts}
        clearProduct={props.clearProduct}
        clearCoordinates={props.clearCoordinates}
        clearAddOn={props.clearAddOn}
        storedQueueId={props.storedQueueId}
        language={props.language}
        userCoordinates={!geolocationError ? coordinates : undefined}
        distanceUnit={getDistanceUnit(props.settings)}
        clearVenue={props.clearVenue}
        clearAll={props.clearAll}
        selectedBookingType={props.selectedBookingType}
        selectedProductHasAddons={props.selectedProductHasAddons}
        isLiveChat={props.isLiveChat}
        showPaymentStep={props.showPaymentStep}
      />
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  const settings = getSettingsResponse(state);
  const isPriceInvolved = getIsPriceInvolved(state);
  const showPaymentStep = Boolean(settings?.paymentEnabled) && isPriceInvolved;
  return {
    isLiveChat: getIsLiveChat(state),
    advisor: getSingleAdvisorResponse(state),
    bookingDate: getBookingDate(state),
    currentLayoutType: getCurrentLayout(state),
    firstPage: getFirstPage(state),
    isLoadingBooking: isLoadingBooking(state),
    isLoadingReservation: isLoadingReservation(state, false),
    isLoadingProduct: isLoadingProduct(state, false),
    isLoadingVenue: isLoadingVenue(state, false),
    isLoadingProducts: isLoadingProducts(state),
    onlyVirtualProducts: getHasOnlyVirtualProducts(state) || false,
    product: getSingleProductResponse(state),
    products: getProductsAggregate(state),
    questions: isLoadingQuestions(state) ? [] : getResponse(state),
    reservation: getReservationResponse(state),
    selectedAddOns: getSelectedAddOns(state),
    storedQueueId: getQueueId(state),
    venue: getSingleVenueResponse(state),
    language: getCurrentLanguage(state),
    selectedBookingType: getSelectedBookingType(state),
    booking: getBookingResponse(state),
    selectedProductHasAddons: getSelectedProductHasAddons(state),
    showPaymentStep,
    settings,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    clearAddOn: () => {
      dispatch(clearAddOn());
    },
    clearProduct: () => {
      dispatch(clearProduct());
    },
    clearCoordinates: () => {
      dispatch(clearCoordinates());
    },
    clearVenue: () => {
      dispatch(clearVenue());
    },
    setLayout: (layoutType: string) => {
      dispatch(setCurrentLayout(layoutType));
    },
    setFirstPage: (page: StepNames) => {
      dispatch(setFirstPage(page));
    },
    clearAll: () => {
      dispatch(clearAll());
    },
    setBookingType: (bookingType: CardBookingValidTypes) => {
      dispatch(setBookingType(bookingType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryContainer);
